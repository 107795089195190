import {
	Box,
	Button,
	Heading,
	Image,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import React from 'react';
import { promoSection } from '../../queries/promoSection';
import useAuth from '../useAuth';

const LoginPromoBanner = () => {
	const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();
	const promoData = promoSection();

	const hasPharmaPromo = promoData.promorepeater.some(
		(promo: any) => promo.ispharma
	);

	return (
		<>
			{!loggedIn && hasPharmaPromo && (
				<Box
					backgroundImage='/bg.webp'
					backgroundRepeat='no-repeat'
					backgroundSize='cover'>
					<Stack
						px={{ base: 3, md: 0 }}
						flexDir={{ base: 'column', lg: 'row' }}
						maxW='1280px'
						mx='auto'
						spacing={0}
						alignItems='center'
						pt={{ base: 16, md: 0 }}>
						<VStack alignItems={{ base: 'center', md: 'flex-start' }}>
							<Text
								color='#262626'
								fontSize={{ base: '3xl', md: '4xl' }}
								fontWeight='700'
								pb={3}>
								Unlock All Offers:{' '}
							</Text>
							<Text
								fontSize='16px'
								color='#262626'
								pb={10}
								textAlign='center'>
								Ensure you're{' '}
								<Text
									as='span'
									color='#262626'
									fontWeight={700}>
									logged in
								</Text>{' '}
								to view the complete range of our promotions
							</Text>
							<Link href='/sign-in'>
								<Button
									color='#000'
									variant='ghost'
									height='48px'
									fontSize='18px'
									fontWeight='600'
									bg='transparent'
									border='1px solid #000'
									borderRadius='1000px'
									_hover={{ bg: '#000', color: '#fff' }}
									width='230px'>
									Log In
								</Button>
							</Link>
						</VStack>
						<Image
							rel='preload'
							src='/woman.webp'
							alt='Grab our exclusive special offers on JUVEDERM®, RESTYLANE®, and more.'
						/>
					</Stack>
				</Box>
			)}

			{(isUnlicensedUser || isDefaultCustomer) && hasPharmaPromo && (
				<Box
					backgroundImage='/bg.webp'
					backgroundRepeat='no-repeat'
					backgroundSize='cover'>
					<Stack
						px={{ base: 3, md: 0 }}
						flexDir={{ base: 'column', lg: 'row' }}
						maxW='1280px'
						mx='auto'
						spacing={0}
						alignItems='center'
						pt={{ base: 16, md: 0 }}>
						<VStack alignItems={{ base: 'center', md: 'flex-start' }}>
							<Text
								color='#262626'
								fontSize={{ base: '3xl', md: '4xl' }}
								fontWeight='700'
								pb={3}>
								Don't miss out on our full offerings and deals.
							</Text>
							<Text
								fontSize='16px'
								color='#262626'
								pb={10}
								textAlign='center'>
								Upgrade your license to view them.
							</Text>
							<Link href='https://talktodoctormedica.youcanbook.me/'>
								<Button
									color='#000'
									variant='ghost'
									height='48px'
									fontSize='18px'
									fontWeight='600'
									bg='transparent'
									border='1px solid #000'
									borderRadius='1000px'
									_hover={{ bg: '#000', color: '#fff' }}
									width='230px'>
									Upgrade Your Licence
								</Button>
							</Link>
						</VStack>
						<Image
							rel='preload'
							src='/woman.webp'
							alt='Grab our exclusive special offers on JUVEDERM®, RESTYLANE®, and more.'
						/>
					</Stack>
				</Box>
			)}
		</>
	);
};

export default LoginPromoBanner;
