import {
	Box,
	Button,
	Heading,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import LoginPromoBanner from '../LoginPromoBanner';
import React from 'react';
import { SingleProductCard } from '../Product/SingleProductCard';
import { brandsToExclude } from '../../queries/toExclude';
import { fetchAllProducts } from '../../queries/fetchAllProducts';
import { promoSection } from '../../queries/promoSection';
import useAuth from '../useAuth';

const ActivePromotions = () => {
	const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();
	const promoData = promoSection();
	const allProducts = fetchAllProducts();

	const matchingProducts = promoData.promorepeater.flatMap((promo: any) =>
		promo.products?.map((product: any) => {
			const matchingProduct = allProducts.find(
				(item: any) =>
					item.sku.toString().trim() === product.sku.toString().trim()
			);
			return matchingProduct
				? {
						...matchingProduct,
						promoTitle: promo.title,
						promoSubtitle: promo.subtitle,
						promoAdditional: promo.additionaltext,
				  }
				: null;
		})
	);

	const filteredProducts = matchingProducts.filter((product: any) => {
		if (!loggedIn || isUnlicensedUser || isDefaultCustomer) {
			const productName = product?.name.toLowerCase();
			return !brandsToExclude.some((brand) =>
				productName?.includes(brand.toLowerCase())
			);
		}
		return true;
	});

	// Filter out pharma promos for unauthorized users
	const filteredPromos = promoData.promorepeater.filter((promo: any) => {
		const isPharma = promo.ispharma;

		// Exclude pharma promos for unauthorized users
		if ((!loggedIn || isUnlicensedUser || isDefaultCustomer) && isPharma) {
			return false;
		}

		return true;
	});

	return (
		<>
			{filteredPromos.map((promo: any, index: number) => {
				const productsForThisPromo = filteredProducts.filter(
					(product: any) => product && product.promoTitle === promo.title
				);

				// If there's only one promo, apply a special gradient background
				const bg =
					filteredPromos.length === 1
						? 'linear-gradient(180deg, #FFF 0%, #FAF3EC 41.15%, #D28C49 100%)'
						: index % 2 === 0
						? '#fff'
						: 'linear-gradient(180deg, #FFBA78 0%, #222A39 100%)';
				const textColor = index % 2 === 0 ? '#000' : '#fff';

				return (
					<Box
						key={promo.title}
						px='20px'
						bg={bg}
						minH={productsForThisPromo.length > 0 ? '706px' : 'auto'}
						py='64px'>
						<VStack
							color={textColor}
							textAlign='center'
							maxW='1024px'
							margin='0 auto'>
							<Heading fontSize={{ base: 'md', md: 'xl' }}>
								{promo.title}
							</Heading>

							{productsForThisPromo.length > 0 ? (
								<Text
									fontSize='16px'
									fontWeight={500} 
                  dangerouslySetInnerHTML={{__html:promo?.additionaltext }}
                  ></Text>
							) : (
								<Link
									href='/category/botulinum'
									_hover={{ textDecoration: 'none' }}>
									<Button
										variant='outline'
										w='100%'
										fontSize='12px'
										fontWeight={600}
										border='1px solid #F8F1F0'
										color='#fff'
										borderRadius='40px'
										mt={2}>
										SAVE ON TOXINS
									</Button>
								</Link>
							)}

							<Text
								fontSize={{ base: 'md', md: '16px' }}
								fontWeight='500'>
								{promo.subtitle}
							</Text>
						</VStack>

						<Box
							maxW='100%'
							w='100%'
							m='0 auto'
							pt='40px'
							display='flex'
							justifyContent='center'
							alignItems='center'>
							<Stack
								display='flex'
								flexDir={{ base: 'column', md: 'row' }}
								gap='8px'
								mx='auto'
								justifyContent='center'
								alignItems='center'>
								{productsForThisPromo.map((product: any) => (
									<SingleProductCard
										key={product.id}
										props={product}
										qtyInputOn={true}
										outlet={false}
										margin={'0 auto'}
										width={'240px'}
										disclaimer={
											'Promo and tiered pricing discounts cannot be combined'
										}
									/>
								))}
							</Stack>
						</Box>
					</Box>
				);
			})}
			<LoginPromoBanner />
		</>
	);
};

export default ActivePromotions;
